import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout/Layout"
import Brand from "../../components/brand/Brand"
import mujer from "../../images/mujer.jpg"
import hombre from "../../images/hombre.jpg"
import "./Inicio.css"

export default () => {
  return (
    <Layout type="M" className="home">
      <Brand type="logo" filetype="svg" isLink={false} />
      <nav className="gender-nav">
        <Link className="gender-nav-item" to="/mujer">
          <picture>
            <img className="gender-nav-item-img" src={mujer} alt="img" />
            <div className="gender-nav-item-title">MUJER</div>
          </picture>
        </Link>
        <Link className="gender-nav-item" to="/hombre">
          <picture>
            <img className="gender-nav-item-img" src={hombre} alt="img" />
            <div className="gender-nav-item-title">HOMBRE</div>
          </picture>
        </Link>
      </nav>
    </Layout>
  )
}
